import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  verifyError: null,
  changeForgottenPass: null,
  setPassword: null,
  changePassTokenIsValid: true,
  setPasswordTokenIsValid: false,
  errorMessage: null,
  model: null
};

const slice = createSlice({
  name: "User Info",
  initialState,
  reducers: {
    setUserInfo: (state, { payload }) => payload,
    logIn: (state, { payload }) => ({
      ...state,
      ...payload,
      errorMessage: null,
      model: { ...payload, verificationToken: null }
    }),
    showQr: (state, { payload }) => ({
      ...state,
      ...payload,
      model: { ...payload, token: null }
    }),
    setLoginErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setLoginAuthErrorMessage: (state, { payload }) => {
      state.errorAuthMessage = payload;
    },
    logOut: () => initialState,
    setForgottenPassChange: (state, { payload }) => {
      state.changeForgottenPass = payload;
    },
    setChangePassTokenIsValid: (state, { payload }) => {
      state.changePassTokenIsValid = payload;
    },
    setPasswordSet: (state, { payload }) => {
      state.setPassword = payload;
    },
    setPasswordSetTokenIsValid: (state, { payload }) => {
      state.setPasswordTokenIsValid = payload;
    },
    setVerifyError: (state, { payload }) => {
      state.verifyError = payload;
    },
    changePasswordExpired: (state, { payload }) => {
      if (payload) {
        state.model = {
          token: payload.model.token,
          userId: payload.model.id,
          passExpired: true
        };
        state.errorMessage = payload.errorMessage;
      } else {
        state.model = { ...state.model, passExpired: false };
      }
    },
    changeUserInfoValue: (state, { payload }) => ({ ...state, ...payload })
  }
});

export const {
  setUserInfo,
  logIn,
  changeUserInfoValue,
  showQr,
  changePasswordExpired,
  setLoginAuthErrorMessage,
  setLoginErrorMessage,
  logOut,
  setForgottenPassChange,
  setChangePassTokenIsValid,
  setPasswordSet,
  setVerifyError,
  setPasswordSetTokenIsValid
} = slice.actions;

export default slice.reducer;
