import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  name: null,
  info: null,
  filters: null,
  doActionAfterPopupClose: false
};

const slice = createSlice({
  name: "Popup Info",
  initialState: defaultState,
  reducers: {
    setPopupName: (state, action) => {
      state.name = action.payload;
    },
    setPopupInfo: (state, action) => {
      state.info = action.payload;
    },
    setPopupFilters: (state, action) => {
      state.filters = action.payload;
    },
    removePopup: state => {
      state.name = defaultState.name;
      state.info = defaultState.info;
      state.filters = defaultState.filters;
    },
    setDoActionAfterPopupClose: state => {
      state.doActionAfterPopupClose = true;
    },
    setActionDoneAfterPopupClose: state => {
      state.doActionAfterPopupClose = false;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => defaultState
    );
  }
});

export const {
  setPopupName,
  setPopupInfo,
  setPopupFilters,
  removePopup,
  setDoActionAfterPopupClose,
  setActionDoneAfterPopupClose
} = slice.actions;
export default slice.reducer;
