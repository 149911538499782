import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCurrency: false,
  switcherDisabled: false,
  updateKey: Date.now()
};

const slice = createSlice({
  name: "View Info",
  initialState,
  reducers: {
    changeUpdateKey: (state, { payload }) => {
      state.updateKey = Date.now();
    },
    toggleCurrencyState: state => {
      state.isCurrency = !state.isCurrency;
    },
    setCurrencyState: (state, {payload}) => {
      state.isCurrency = payload;
    },
    toggleDisableSwitcher: (state, {payload}) => {
      state.switcherDisabled = !state.switcherDisabled;
    }
  }
});

export const { changeUpdateKey, toggleCurrencyState, setCurrencyState, toggleDisableSwitcher } = slice.actions;

export default slice.reducer;
