import { createSlice } from "@reduxjs/toolkit";
import { parseDateToLocalAndFormat } from "../../../helpers/localHelperFunctions";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../constants";

const defaultState = {
  data: {},
  error: "",
  loader: false,
  disabled: false,
  backToParent: false,
  refetchPageData: false,
  areFiltersOpened: true,
  additionalDataOne: null,
  additionalDataTwo: null,
  tableColumnsFiltered: null,
  singleDataHistoryUrl: null,
  currentComponentUpdate: false,
  permissionGroupDataCreatedByUser: [],
};

const slice = createSlice({
  name: "Page Table Data",
  initialState: defaultState,
  reducers: {
    setData: (state, action) => {
      function dataHelper(array, name) {
        for (let i = 0, len = array.length; i < len; ++i) {
          array[i].date = parseDateToLocalAndFormat(array[i].date, DATE_FORMAT);
          array[i].name = name;
        }
      }
      const { key, res: payload } = action.payload;
      const { format } = payload;
      for (const key in payload?.model) {
        if (Object.hasOwn(payload.model, key)) {
          if (
            key === "tournamentListResponseModel" ||
            key === "gameListModel" ||
            key === "userListModel"
          ) {
            payload.model = {
              ...payload.model[key],
              requestedPageNumber: payload.model["requestedPageNumber"],
            };
          }
        }
      }
      payload.model?.list?.forEach((item) => {
        if (item.hasOwnProperty("startedDate")) {
          item.startedDate = parseDateToLocalAndFormat(
            item.startedDate,
            format ?? DATE_TIME_FORMAT
          );
        }
        if (item.hasOwnProperty("finishedDate")) {
          item.finishedDate = parseDateToLocalAndFormat(
            item.finishedDate,
            format ?? DATE_TIME_FORMAT
          );
        }
        if (item.hasOwnProperty("finishDate")) {
          item.finishDate = parseDateToLocalAndFormat(
            item.finishDate,
            format ?? DATE_TIME_FORMAT
          );
        }
        if (item.hasOwnProperty("startDate")) {
          item.startDate = parseDateToLocalAndFormat(
            item.startDate,
            format ?? DATE_TIME_FORMAT
          );
        }
        if (item.hasOwnProperty("endDate")) {
          item.endDate = parseDateToLocalAndFormat(
            item.endDate,
            format ?? DATE_TIME_FORMAT
          );
        }
      });
      delete payload.format;
      payload?.permissionGroups?.forEach((permissionGroup) => {
        if (permissionGroup.hasOwnProperty("creationDate")) {
          permissionGroup.creationDate = parseDateToLocalAndFormat(
            permissionGroup.creationDate,
            DATE_TIME_FORMAT
          );
        }
      });
      if (payload?.model?.hasOwnProperty("betsWithDate")) {
        dataHelper(payload.model.betsWithDate, "Bet Amount");
      }
      if (payload?.model?.hasOwnProperty("winWithDates")) {
        dataHelper(payload.model.winWithDates, "Win Amount");
      }
      if (payload?.model?.hasOwnProperty("rakeWithDates")) {
        dataHelper(payload.model.rakeWithDates, "Rake Amount");
      }
      if (payload?.model?.hasOwnProperty("gameCountWithDates")) {
        dataHelper(payload.model.gameCountWithDates, "Game Count");
      }
      if (payload?.model?.hasOwnProperty("uniquePlayerCountWithDates")) {
        dataHelper(payload.model.uniquePlayerCountWithDates, "Players");
      }
      state.data = { ...state.data, [key]: payload };
      state.loader = false;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload;
    },
    setTableColumnsFiltered: (state, action) => {
      state.tableColumnsFiltered = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAdditionalDataOne: (state, action) => {
      state.additionalDataOne = action.payload;
    },
    resetAdditionalDataOne: (state) => {
      state.additionalDataOne = defaultState.additionalDataOne;
    },
    setAdditionalDataTwo: (state, action) => {
      state.additionalDataTwo = action.payload;
    },
    resetAdditionalDataTwo: (state) => {
      state.additionalDataTwo = defaultState.additionalDataTwo;
    },
    setPermissionGroupDataCreatedByUser: (state, action) => {
      state.permissionGroupDataCreatedByUser = action.payload;
    },
    resetPermissionGroupDataCreatedByUser: (state) => {
      state.permissionGroupDataCreatedByUser =
        defaultState.permissionGroupDataCreatedByUser;
    },
    setAreFiltersOpened: (state, action) => {
      state.areFiltersOpened = action.payload;
    },
    setSingleDataHistoryUrl: (state, action) => {
      state.singleDataHistoryUrl = action.payload;
    },
    resetSingleDataHistoryUrl: (state) => {
      state.singleDataHistoryUrl = defaultState.singleDataHistoryUrl;
    },
    setCurrentComponentUpdateToggle: (state) => {
      state.currentComponentUpdate = !state.currentComponentUpdate;
    },
    addCurrencyData: (state, { payload }) => {
      state.data.currencies.model.currencies.unshift(payload);
    },
    editCurrencyData: (state, { payload: { id, title } }) => {
      state.data.currencies.model.currencies.find(
        (item) => item.id === id
      ).title = title;
    },
    setBackToParentPage: (state) => {
      state.backToParent = !state.backToParent;
    },
    setRefetchPageData: (state) => {
      state.refetchPageData = !state.refetchPageData;
    },
    insertPartnersDataIntoGamesData: (state, action) => {
      const { data } = state;
      const { key, res } = action.payload ?? {};
      const { gameKindId, partnerBetStatisticList } = res?.model;
      if (data?.[key]?.length) {
        const gameToInsert = data?.[key]?.find(
          (item) => item.gameKindId === gameKindId
        );
        gameToInsert.partnerBetStatisticList = partnerBetStatisticList;
      }
    },
    removeAllData: (state) => {
      state.data = defaultState.data;
      state.loader = defaultState.loader;
      state.tableColumnsFiltered = defaultState.tableColumnsFiltered;
      state.additionalDataOne = defaultState.additionalDataOne;
      state.additionalDataTwo = defaultState.additionalDataTwo;
      state.error = defaultState.error;
      state.permissionGroupDataCreatedByUser =
        defaultState.permissionGroupDataCreatedByUser;
      state.areFiltersOpened = defaultState.areFiltersOpened;
      state.singleDataHistoryUrl = defaultState.singleDataHistoryUrl;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => defaultState
    );
  },
});

export const {
  setData,
  setError,
  setLoader,
  setDisabled,
  removeAllData,
  setRefetchPageData,
  setBackToParentPage,
  setAreFiltersOpened,
  editCurrencyData,
  addCurrencyData,
  setAdditionalDataOne,
  setAdditionalDataTwo,
  resetAdditionalDataOne,
  resetAdditionalDataTwo,
  setTableColumnsFiltered,
  setSingleDataHistoryUrl,
  resetSingleDataHistoryUrl,
  setCurrentComponentUpdateToggle,
  insertPartnersDataIntoGamesData,
  setPermissionGroupDataCreatedByUser,
  resetPermissionGroupDataCreatedByUser,
} = slice.actions;

export default slice.reducer;
