import { createSlice } from "@reduxjs/toolkit";
import { parseDateToLocalAndFormat } from "../../../helpers/localHelperFunctions";
import { DATE_TIME_FORMAT } from "../../../constants";

const defaultState = {
  data: null,
  additionalData: null,
  loader: false,
  error: ""
};

const slice = createSlice({
  name: "Secondary Page Data",
  initialState: defaultState,
  reducers: {
    setModel: (state, action) => {
      const {
        payload: { res: payload, key }
      } = action;
      const { model } = payload ?? {};
        if (Object.hasOwn(model, "gameConvention") && model.gameConvention && typeof model.gameConvention !== "object") {
          model.gameConvention = JSON.parse(model.gameConvention);
        }

      if (
        Object.hasOwn(model, "tournamentTours") &&
        model?.tournamentTours?.length
      ) {
        model.tournamentTours.forEach(tour => {
          for (const key in tour) {
            if (Object.hasOwn(tour, key)) {
              if (
                (key === "startedDate" || key === "finishedDate") &&
                tour[key]
              ) {
                tour[key] = parseDateToLocalAndFormat(
                  tour[key],
                  DATE_TIME_FORMAT
                );
              }
            }
          }
        });
      }
      if (Object.hasOwn(model, "tournamentPlayersModel")) {
        const { list } = model.tournamentPlayersModel;
        if (list?.length) {
          list.forEach(item => {
            for (const key in item) {
              if (Object.hasOwn(item, key)) {
                if (key === "eventTime") {
                  item[key] = parseDateToLocalAndFormat(
                    item[key],
                    DATE_TIME_FORMAT
                  );
                }
              }
            }
          });
        }
      }
      if (
        Object.hasOwn(model, "tourGameListModel") &&
        model.tourGameListModel
      ) {
        model.listModel = model.tourGameListModel;
        delete model.tourGameListModel;
      }
      state.data = {
        ...state.data,
        [key]: payload
      };
      state.loader = false;
    },
    setAdditionalData: (state, action) => {
      state.additionalData = action.payload;
    },
    setSecondaryLoader: (state, action) => {
      state.loader = action.payload;
    },
    setSecondaryError: (state, action) => {
      state.error = action.payload;
    },
    removeSecondaryData: state => {
      state.data = defaultState.data;
      state.additionalData = defaultState.additionalData;
      state.loader = defaultState.loader;
      state.error = defaultState.error;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => defaultState
    );
  }
});

export const {
  setModel,
  setAdditionalData,
  setSecondaryError,
  setSecondaryLoader,
  removeSecondaryData
} = slice.actions;

export default slice.reducer;
