import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../../constants";

const defaultState = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  isLastPage: false,
  dontSendRequest: false
};

const slice = createSlice({
  name: "Single User Info",
  initialState: defaultState,
  reducers: {
    setPaginationData: (state, action) => {
      const { page, pageSize } = action.payload;
      state.page = page;
      state.pageSize = pageSize;
    },
    setPageNumber: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setIsLastPage: (state, action) => {
      state.isLastPage = action.payload;
    },
    setDontSendRequest: (state, action) => {
      state.dontSendRequest = action.payload;
    },
    resetPaginationData: () => defaultState
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => defaultState
    );
  }
});

export const {
  setPaginationData,
  setPageNumber,
  setPageSize,
  setIsLastPage,
  setDontSendRequest,
  resetPaginationData
} = slice.actions;

export default slice.reducer;
