import { batch } from "react-redux";
import ApiHandler from "../connection/API/ApiHandler";
import {
  logIn,
  logOut,
  showQr,
  setVerifyError,
  changeUserInfoValue,
  changePasswordExpired,
  setLoginErrorMessage,
  setForgottenPassChange,
  setLoginAuthErrorMessage,
  setChangePassTokenIsValid,
  setPasswordSetTokenIsValid
} from "../store/slices/userInfo";
import { processUserPermissions } from "../helpers/localHelperFunctions";
import {
  setBackToParentPage,
  setCurrentComponentUpdateToggle,
  setDisabled,
  setLoader,
  setData
} from "../store/slices/pageTableData";
import { setPageNumber } from "../store/slices/paginationsData";
import { setFilters } from "../store/slices/filtersData";
import { removePopup } from "../store/slices/popupInfo";

const PREFIX = "api/User";

const END_POINTS = {
  LOGIN: "Login",
  VERIFY: "Verify",
  LOG_OUT: "LogOut",
  CHANGE_EXPIRED_PASS: "ChangeExpiredPassword",
  FORGOT_PASS: "ForgotPassword",
  CHANGE_FORGOTTEN_PASS: "ChangeForgotedPassword",
  CHANGE_PASSWORD: "ChangePassword",
  VERIFY_EMAIL: "VerifyEmail",
  CHECK_FORGOTTEN_PASS_TOKEN: "ValidateForgotedPasswordToken",
  CHECK_SET_PASS_TOKEN: "ValidateEmailVerificationToken",
  GET_USERS: "GetUsers",
  GET_USERS_FILTERS: "GetUsersFilters",
  CHANGE_USER_STATUS: "ChangeUserStatus",
  USER_VIEW: "UserView",
  CREATE_USER: "CreateUser",
  EDIT_USER: "EditUser",
  GET_USER_PERMISSIONS: "GetUserPermissions",
  GET_EDIT_USER: "GetEditeUserData",
  RESET_QR: "Reset2FACode",
  SEND_VERIFICATION_EMAIL: "SendVerificationEmail"
};

class UserService extends ApiHandler {
  userVerify = code => {
    return async (dispatch, getState) => {
      try {
        const { verificationToken } = getState()?.userInfo?.model;
        const body = {
          code,
          verificationToken
        };

        const result = await this.post(END_POINTS.VERIFY, body, {}, true);
        const { errorMessage, model } = result;

        if (errorMessage) {
          dispatch(setVerifyError(errorMessage));
        } else {
          batch(() => {
            if (model) {
              model.userPermissions = processUserPermissions(
                model?.userPermissions
              );
              dispatch(logIn(model));
              dispatch(setLoginErrorMessage(null));
              dispatch(setLoginAuthErrorMessage(null));
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
  };

  userLogin = (body, navigate) => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const result = await this.post(END_POINTS.LOGIN, body, {}, true);
        dispatch(setLoader(false));
        const { model, errorMessage } = result;
        const { token, userPermissions, isShowQRCode, verificationToken } =
          model ?? {};

        if (isShowQRCode || verificationToken) {
          batch(() => {
            dispatch(showQr(model));
            dispatch(setLoginAuthErrorMessage(errorMessage));
          });
        } else if (token && !errorMessage) {
          model.userPermissions = processUserPermissions(userPermissions);
          dispatch(logIn(model));
        } else if (errorMessage) {
          if (errorMessage === "Password Expired" && model.token) {
            navigate("/change-expired-password");
            dispatch(changePasswordExpired(result));
          } else {
            dispatch(setLoginErrorMessage(errorMessage));
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
  };

  userLogOut = navigate => {
    return async (dispatch, getState) => {
      const token = getState()?.userInfo?.model?.token;
      if (token) {
        try {
          const result = await this.post(END_POINTS.LOG_OUT, { token });
          const { model, errorMessage } = result;
          if (model) {
            dispatch(logOut());
            navigate("/login");
          } else if (errorMessage) {
            dispatch(setLoginErrorMessage(errorMessage));
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
  };

  changeExpiredPass = body => {
    return async dispatch => {
      try {
        const res = await this.post(END_POINTS.CHANGE_EXPIRED_PASS, body);
        if (!res?.model) {
          dispatch(
            changeUserInfoValue({
              changePassTokenIsValid: true,
              errorMessage: res?.errorMessage || "Something went wrong"
            })
          );
          return { errorMessage: res?.errorMessage || "Something went wrong" };
        } else {
          dispatch(
            changeUserInfoValue({
              errorMessage: null,
              model: true
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
  };

  forgotPassword = body => {
    return async () => {
      try {
        await this.post(END_POINTS.FORGOT_PASS, body, {}, true);
      } catch (e) {
        console.log(e);
      }
    };
  };

  changeForgottenPass = body => {
    return async dispatch => {
      try {
        const result = await this.post(
          END_POINTS.CHANGE_FORGOTTEN_PASS,
          body,
          {},
          true
        );
        if (!result?.model) {
          dispatch(setLoginErrorMessage(result?.errorMessage));
        } else {
          dispatch(setForgottenPassChange(result));
        }
        return result;
      } catch (e) {
        console.log(e);
      }
    };
  };

  changePassword = body => {
    return async dispatch => {
      try {
        const res = await this.post(END_POINTS.CHANGE_PASSWORD, body, {}, true);
        if (res?.errorMessage) {
          dispatch(setLoginErrorMessage(res.errorMessage));
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    };
  };

  checkForgottenPassToken = body => {
    return async dispatch => {
      try {
        const result = await this.post(
          END_POINTS.CHECK_FORGOTTEN_PASS_TOKEN,
          body
        );
        dispatch(setChangePassTokenIsValid(result.model));
      } catch (e) {
        console.log(e);
      }
    };
  };

  checkSetPasswordToken = body => {
    return async dispatch => {
      try {
        const result = await this.post(END_POINTS.CHECK_SET_PASS_TOKEN, body);
        dispatch(setPasswordSetTokenIsValid(result.model));
      } catch (e) {
        console.log(e);
      }
    };
  };

  setPassword = (body, navigate) => {
    return async (dispatch, getState) => {
      try {
        dispatch(setLoader(true));
        const result = await this.post(END_POINTS.VERIFY_EMAIL, body);
        const token = getState()?.userInfo?.model?.token;

        if (token && result.model) {
          const logOutRes = await this.post(END_POINTS.LOG_OUT, { token });
          const { model, errorMessage } = logOutRes;
          if (model) {
            dispatch(logOut());
            navigate("/login");
          } else if (errorMessage) {
            dispatch(setLoginErrorMessage(errorMessage));
          }
        } else if (result.model) {
          navigate("/login");
        } else if (result.errorMessage) {
          dispatch(setLoginErrorMessage(result.errorMessage));
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  getUsersFilters = () => {
    return async dispatch => {
      try {
        dispatch(setDisabled(true));
        const result = await this.get(END_POINTS.GET_USERS_FILTERS);
        dispatch(setDisabled(false));
        dispatch(setFilters(result));
      } catch (e) {
        console.log(e);
      }
    };
  };

  getUsers = body => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const result = await this.post(END_POINTS.GET_USERS, body);
        dispatch(setData({ res: result, key: "users" }));
        const { lastPageNumber } = result?.model ?? null;
        if (lastPageNumber) {
          dispatch(setPageNumber(lastPageNumber));
        }
      } catch (e) {
        console.log(e);
      }
    };
  };

  changeUserStatus = body => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        await this.post(END_POINTS.CHANGE_USER_STATUS, body);
        batch(() => {
          dispatch(removePopup());
          dispatch(setCurrentComponentUpdateToggle());
        });
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  resetUserQr = query => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const res = await this.post(END_POINTS.RESET_QR, null, query);
        batch(() => {
          if (res?.model) {
            dispatch(removePopup());
          }
          dispatch(setCurrentComponentUpdateToggle());
        });
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  getUser = body => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const result = await this.get(END_POINTS.USER_VIEW, body);
        if (result?.model?.permissions?.length) {
          result.model.permissions = processUserPermissions(
            result.model.permissions
          );
        }
        return result;
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  getUserPermissions = queryFields => {
    return async () => {
      try {
        const result = await this.get(
          END_POINTS.GET_USER_PERMISSIONS,
          queryFields
        );
        if (result?.model?.userPermissions) {
          result.model.userPermissions = processUserPermissions(
            result.model.userPermissions
          );
        }
        return result;
      } catch (e) {
        console.log(e);
      }
    };
  };

  getEditUser = queryFields => {
    return async () => {
      try {
        const result = await this.get(END_POINTS.GET_EDIT_USER, queryFields);
        if (result?.model?.userPermissions) {
          result.model.permissions = processUserPermissions(
            result.model.userPermissions
          );
          delete result.model.userPermissions;
        }
        return result;
      } catch (e) {
        console.log(e);
      }
    };
  };

  sendVerificationEmail = query => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const result = await this.post(
          END_POINTS.SEND_VERIFICATION_EMAIL,
          null,
          query
        );
        return result;
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  createUser = body => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const res = await this.post(END_POINTS.CREATE_USER, body);
        batch(() => {
          dispatch(removePopup());
          if (res?.model) {
            dispatch(setBackToParentPage());
            dispatch(setCurrentComponentUpdateToggle());
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };

  editUser = (body, queryFields) => {
    return async dispatch => {
      try {
        dispatch(setLoader(true));
        const res = await this.put(END_POINTS.EDIT_USER, body, queryFields);
        if (res?.model && !res?.errorMessage) {
          batch(() => {
            dispatch(removePopup());
            dispatch(setBackToParentPage());
            dispatch(setCurrentComponentUpdateToggle());
          });
        } else {
          dispatch(removePopup());
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoader(false));
      }
    };
  };
}

export const userApiHandler = new UserService(PREFIX);
