import { statusFilterHelper } from "../../../helpers/localHelperFunctions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  errorMessage: null,
  model: null,
  secondModel: null
};

const slice = createSlice({
  name: "Filters Data",
  initialState,
  reducers: {
    setFilters: function(state, action) {
      const model = action.payload?.model;

      if (model) {
        filterHelper(model);
        state.model = model;
      }
    },
    setSecondFilters: (state, action) => {
      const model = action.payload?.model;

      if (model) {
        filterHelper(model);
        state.secondModel = model;
      }
    },
    mergeSecondFilters: function(state, action) {
      const model = action.payload?.model;

      if (model) {
        filterHelper(model);
        state.secondModel = {...state.secondModel, ...model};
      }
    },
    setFiltersError: function(state, action) {
      state.errorMessage = action.payload.errorMessage;
    },
    resetFilters: function(state) {
      state.model = initialState.model;
      state.errorMessage = initialState.errorMessage;
    },
    resetFiltersSecondModel: (state) => {
      state.secondModel = initialState.secondModel;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => initialState
    );
  }
});

export const {
  setFilters, setFiltersError,
  resetFilters,
  setSecondFilters,
  mergeSecondFilters,
  resetFiltersSecondModel
} = slice.actions;

export default slice.reducer;

function filterHelper(model) {
  if (Object.hasOwn(model, "gameFinishTypes")) {
    statusFilterHelper(model.gameFinishTypes);
  }
  if (Object.hasOwn(model, "tournamentStatuses")) {
    statusFilterHelper(model.tournamentStatuses);
  }
}
