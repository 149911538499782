import { createSlice } from "@reduxjs/toolkit";

const initialState = { orders: [] };

const slice = createSlice({
  name: "Orders",
  initialState: initialState,
  reducers: {
    setAscending: (state, action) => {
      state.orders[0] = action.payload;
    },
    setDescending: (state, action) => {
      state.orders[0] = action.payload;
    },
    resetOrders: state => {
      state.orders = initialState.orders;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => initialState
    );
  }
});

export const { setAscending, setDescending, resetOrders } = slice.actions;

export default slice.reducer;
