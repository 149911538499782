import React, { Suspense, lazy, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { VISIBILITY_CHANGE } from "./helpers/localHelperFunctions";
import { userApiHandler } from "./services/UserService";
import { getAppData, useShallowSelector } from "./store/selectors";

// pages
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const BackOffice = lazy(() => import("./pages/BackOffice"));

function App() {
  const { token } = useShallowSelector(getAppData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let logoutTimer;

  const logOutTimer = useCallback(() => {
    clearTimeout(logoutTimer);
    if (token) {
      logoutTimer = setTimeout(() => {
        console.log("Session Timeout");
        dispatch(userApiHandler.userLogOut(navigate));
      }, 1800000);
    }
  }, [token]);

  useEffect(() => {
    window.addEventListener(VISIBILITY_CHANGE, logOutTimer);
    window.addEventListener("mousemove", logOutTimer);
    window.addEventListener("dblclick", logOutTimer);
    window.addEventListener("scroll", logOutTimer);
    window.addEventListener("click", logOutTimer);
    window.addEventListener("wheel", logOutTimer);

    return () => {
      window.removeEventListener(VISIBILITY_CHANGE, logOutTimer);
      window.removeEventListener("mousemove", logOutTimer);
      window.removeEventListener("dblclick", logOutTimer);
      window.removeEventListener("scroll", logOutTimer);
      window.removeEventListener("click", logOutTimer);
      window.removeEventListener("wheel", logOutTimer);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/change-password/:email/:token"
          element={<ChangePassword forgotPass />}
        />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route
          path="/change-expired-password"
          element={<ChangePassword passExpired />}
        />
        <Route path="/verify-email/:email/:token" element={<SetPassword />} />
        <Route path="*" element={<BackOffice />} />
      </Routes>
    </Suspense>
  );
}

export default App;
