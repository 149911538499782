import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  description: null,
  type: null,
  msg: null
};

const slice = createSlice({
  name: "Notifications",
  initialState: initialState,
  reducers: {
    resetNotificationData: () => initialState,
    setNotificationData: (state, action) => action.payload
  },
  extraReducers: builder => {
    builder.addMatcher(
      ({ type }) => type.endsWith("logOut"),
      () => initialState
    );
  }
});

export const { setNotificationData, resetNotificationData } = slice.actions;
export default slice.reducer;
