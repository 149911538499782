import {
  viewData,
  notificationData,
  userInfo,
  popupInfo,
  orders,
  pageTableData,
  paginationsData,
  filtersData,
  secondaryPageData
} from "./slices";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

const reducers = combineReducers({
  viewData,
  userInfo,
  popupInfo,
  notificationData,
  ordersData: orders,
  pageTableData,
  secondaryPageData,
  paginationsData,
  filtersData
});

const persistConfig = {
  key: "login",
  version: 1,
  storage,
  whitelist: ["userInfo"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  devTools: process.env.NODE_ENV === "development"
});

export default store;
